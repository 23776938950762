import React, { useState } from 'react';
import EEGImage from 'assets/images/brandname.svg';
import { Input, Dropdown, Image, Button } from 'components/elements/FormComponent';
import { SearchOutlined, CaretDownOutlined } from 'components/elements/IconComponent';
import { Menu, Typography, Space, Carousel, Tooltip } from 'components/elements/AntComponent';
import DoctorIcon from 'assets/images/doctor.png';
import Img1 from 'assets/images/psychedelic-images/post1.png';
import Img2 from 'assets/images/psychedelic-images/medic2.png';
import Imgs from 'assets/images/psychedelic-images/img1.png';
//ellipses
import Eli1 from 'assets/images/psychedelic-images/Ellipse 619.png';
import Eli2 from 'assets/images/psychedelic-images/Ellipse 621.png';
import Eli3 from 'assets/images/psychedelic-images/Ellipse 620.png';
import Eli4 from 'assets/images/psychedelic-images/Ellipse 622.png';
import Eli5 from 'assets/images/psychedelic-images/Ellipse 623.png';
import Eli6 from 'assets/images/psychedelic-images/Ellipse 624.png';
//rectange
import Rect1 from 'assets/images/psychedelic-images/Rectangle 34624553.png';
import Rect2 from 'assets/images/psychedelic-images/Rectangle 34624554.png';
import Rect3 from 'assets/images/psychedelic-images/Rectangle 34624555.png';
import Rect4 from 'assets/images/psychedelic-images/Rectangle 34624556.png';
import Rect5 from 'assets/images/psychedelic-images/Rectangle 34624557.png';
import Rect6 from 'assets/images/psychedelic-images/Rectangle 34624563.png';
import SavePost from 'assets/images/psychedelic-images/save.png';

import TechImg from 'assets/images/psychedelic-images/tech.png';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;
const contentStyle = {
    margin: 0,
    height: '300px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
export default function PsychedelicSearch() {
    const navigate = useNavigate();
    const [current, setCurrent] = useState('mail');
    const onClick = (e) => {
        setCurrent(e.key);
    };
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const items = [
        {
            label: 'Change Password',
            key: '1',
        },
        {
            label: 'Logout',
            key: '2',
        },
    ];
    const items1 = [
        {
            label: 'Home',
            key: 'mail',
        },
        {
            label: 'Favorites',
            key: 'app',
        },
        {
            label: 'Bookmarks',
            key: 'SubMenu',
            children: [
                {
                    type: 'group',
                    label: 'Item 1',
                    children: [
                        {
                            label: 'Option 1',
                            key: 'setting:1',
                        },
                        {
                            label: 'Option 2',
                            key: 'setting:2',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'Item 2',
                    children: [
                        {
                            label: 'Option 3',
                            key: 'setting:3',
                        },
                        {
                            label: 'Option 4',
                            key: 'setting:4',
                        },
                    ],
                },
            ],
        },
    ];

    const handleMenuClick = (e) => {
        if (e.key === '2') {
            // showModal();
        }
        if (e.key == '1') {
            // setPassModal(true);
        }
    };
    const medicUrl = 'https://www.hopkinsmedicine.org/news/newsroom/';
    const gotoPage = (val) => {
        navigate('/view-site', {
            state: {
                data: val,
            },
        });
    };

    const topics = [
        { id: 1, icon: Eli1, title: 'Brain Topics' },
        { id: 2, icon: Eli2, title: 'Brain Capture' },
        { id: 3, icon: Eli3, title: 'Neuro guide' },
        { id: 4, icon: Eli4, title: 'Z Score in EEG' },
        { id: 5, icon: Eli5, title: 'Side Effect of confusion' },
        { id: 6, icon: Eli6, title: 'Alzheimer`s' },
        { id: 7, icon: Eli1, title: 'ADHD' },
        { id: 8, icon: Eli4, title: 'Z Score in EEG' },
        { id: 9, icon: Eli5, title: 'Side Effect of confusion' },
        { id: 10, icon: Eli6, title: 'Alzheimer`s' },
        { id: 11, icon: Eli1, title: 'ADHD' },
    ];

    const post = [
        {
            id: 1,
            icon: Img1,
            title: 'Using Quantitative Encephalogram (qEEG) to Improve Diagnoses and Drug Response Monitoring in Mental Health',
            url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4592297/',
            description:
                'In the medical practice, it is generally accepted that medication should be prescribed by a clinician only after an accurate evaluation of the patient’s symptoms and complaints, followed by an attempt to find a link with objectively determined anatomical, physiological and biochemical anomalies in the body. In most cases however, mental health professionals can avail only of a limited number of biomarkers when attempting to formulate a diagnosis, having to mainly rely on the observation of the patient’s behavior or on data acquired through selfadministered questionnaires [1]. In doing so, a diagnosis is typically made without strong evidence that the patient’s behavioral symptoms are directly related to precise imbalances in the nervous system which, in most cases, leads to a “trial and error” approach, when it comes to choosing the most appropriate intervention. Medications are then prescribed to treat a pool of symptoms and, if a therapeutic goal is not effectively reached, additional drugs are recommended, in the hope that the overall side effects will not worsen the patient’s clinical profile [2]',
        },
        {
            id: 2,
            icon: Img1,
            title: 'Psilocybin eases existential anxiety in people with life threatening cancer',
            url: 'https://www.hopkinsmedicine.org/news/newsroom/',
            description: ` In a small double-blind study, Johns Hopkins researchers report that a substantial majority of people suffering cancer-related anxiety or depression found considerable relief for up to
                                    six months from a single large dose of psilocybin — the active compound in hallucinogenic “magic mushrooms.”`,
        },
    ];

    const smPost = [
        {
            id: 1,
            icon: Rect1,
            title: 'Neuro Science',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '3 hours ago',
        },
        {
            id: 2,
            icon: Rect2,
            title: 'Zero Law',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '5 hours ago',
        },
        {
            id: 3,
            icon: Rect3,
            title: 'Connection',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '6 hours ago',
        },
        {
            id: 4,
            icon: Rect4,
            title: 'Montage',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '1 day ago',
        },
        {
            id: 5,
            icon: Rect5,
            title: 'Neuro Science',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '3 hours ago',
        },
        {
            id: 6,
            icon: Rect6,
            title: 'Zero Law',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '5 hours ago',
        },
        {
            id: 7,
            icon: Rect1,
            title: 'Connection',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '6 hours ago',
        },
        {
            id: 8,
            icon: Rect2,
            title: 'Montage',
            description: `The itel Unicorn is a
smartwatch that can also be
worn as a pendant`,
            hour: '1 day ago',
        },
    ];
    return (
        <div>
            <div className="topbar bg-white py-2 px-4 shadow-sm d-flex">
                <img className="logo" src={EEGImage} alt="EEG Logo" height="40px" />
                <div className="ms-auto my-auto col-auto justify-content-between d-flex">
                    <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" className="psychedelic-menu" items={items1} />
                    <Input prefix={<SearchOutlined />} placeholder="Search topics" className="my-auto " />
                    <div className="col-md-4">
                        <div className="d-flex w-100 ">
                            <div className="my-auto user-name col">
                                <div className="antrow  row mx-0 my-auto">
                                    <b className="text-capitalize text-end pe-1 fs-15">{localStorage.getItem('firstname') + ' ' + localStorage.getItem('lastname')}</b>
                                </div>
                                <div className="antrow my-auto text-end">
                                    <Text className="text-uppercase text-secondary fw-500 fs-12 pe-1">
                                        <span className="text-lowercase text-secondary fw-500 pe-1 fs-12">pavithramarie.p@kosoft.co</span>
                                    </Text>
                                </div>
                            </div>

                            <div className="col-md-auto">
                                <div className="avatar mx-auto d-flex ">
                                    <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={['click']} className="col-auto ms-1 my-auto">
                                        <Space>
                                            <img src={DoctorIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1 ms-2" alt="user icon" />
                                            {/* {showResearch ? (
                                                    <img src={ResearchIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1 ant-btn-primary" alt="user icon" />
                                                ) : (
                                                    <img src={DoctorIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1" alt="user icon" />
                                                )} */}
                                            {/* <Avatar className="anticonuser text-center col-auto h-75 my-auto mt-1" size={36} icon={<UserOutlined />} /> */}
                                            <CaretDownOutlined />
                                        </Space>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-2 py-2 bg-srch">
                <div className="bg-white p-3 row m-0">
                    <div className="col-md-9 px-2">
                        <Carousel afterChange={onChange}>
                            <div className="d-flex mb-4">
                                {/* <h3 style={contentStyle}>1</h3> */}
                                <div className="col-md-6">
                                    <Image src={Imgs} preview={false} />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="w-75">Zoom Conversations vs In-Person: Brain Activity Tell a Different Tale</h3>
                                    <p style={{ fontSize: '18px' }}>
                                        Zoom Conversations vs In-Person: Brain Activity Tells a Different Tale. Summary: A new study reveals a significant disparity in neural activity during face-to-face conversations
                                        compared to Zoom interactions. Using advanced neuroimaging, researchers observed suppressed neural signals during online exchanges.
                                    </p>
                                    <span className="text-primary">Updated: </span>
                                    <span> June 01 , 2024 07:27 PM IST</span>
                                </div>
                            </div>
                            <div className="d-flex mb-4">
                                {/* <h3 style={contentStyle}>1</h3> */}
                                <div className="col-md-6">
                                    <Image src={Img1} preview={false} />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="w-75">Zoom Conversations vs In-Person: Brain Activity Tell a Different Tale</h3>
                                    <p style={{ fontSize: '18px' }}>
                                        Zoom Conversations vs In-Person: Brain Activity Tells a Different Tale. Summary: A new study reveals a significant disparity in neural activity during face-to-face conversations
                                        compared to Zoom interactions. Using advanced neuroimaging, researchers observed suppressed neural signals during online exchanges.
                                    </p>
                                    <span className="text-primary">Updated: </span>
                                    <span> June 01 , 2024 07:27 PM IST</span>
                                </div>
                            </div>
                            <div>
                                <h3 style={contentStyle}>3</h3>
                            </div>
                            <div>
                                <h3 style={contentStyle}>4</h3>
                            </div>
                        </Carousel>
                        <div className="my-3">
                            <div className="d-flex">
                                {' '}
                                <h4>Saved Topic</h4> <h6 className="text-primary ms-auto">See all</h6>
                            </div>
                            <div className="d-flex overflow-hidden">
                                {topics.map((item) => {
                                    return (
                                        <div className="col-md-1 me-4 text-center" key={item.id}>
                                            <img src={item.icon} height={100} />
                                            <p className="fs-16">{item.title}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <h4 className="pt-2 pb-1">Post</h4>
                        <div className="mt-2">
                            {post.map((item) => {
                                return (
                                    <div key={item.id} className="d-flex mb-4">
                                        <div className="col-md-4">
                                            <Image src={item.icon} preview={false} />
                                        </div>
                                        <div className="col-md-8 ps-3">
                                            <div className="d-flex">
                                                <h4>{item.title}</h4>
                                                <div className="pointer ms-auto">
                                                    <Tooltip title="Save Post">
                                                        <img src={SavePost} height={50} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <p>
                                                {item.description}
                                                <span className="ps-2 text-primary pointer" onClick={() => gotoPage(item)}>
                                                    see more..
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* <div className="d-flex my-3 border-top pt-2">
                            <Image src={Img2} className="col-md-8" preview={false} style={{ height: '278px' }} />
                            <div className="col ps-3">
                                <h2>Psilocybin eases existential anxiety in people with life threatening cancer</h2>
                                <p style={{ textAlign: 'justify' }}>
                                    In a small double-blind study, Johns Hopkins researchers report that a substantial majority of people suffering cancer-related anxiety or depression found considerable relief for up to
                                    six months from a single large dose of psilocybin — the active compound in hallucinogenic “magic mushrooms.”
                                </p>
                                <Button type="primary" onClick={gotoPage}>
                                    Read More...
                                </Button>
                            </div>
                        </div>
                   */}
                    </div>
                    <div className="col-md-3 pe-0" style={{ borderLeft: '2px solid lightgray' }}>
                        <div className="mb-2 text-center">
                            <Image src={TechImg} />
                        </div>
                        <div className="px-3 mt-4">
                            {smPost?.map((item) => {
                                return (
                                    <div key={item.id} className="mb-3 border-bottom pb-2">
                                        <div className="d-flex">
                                            <p className="fw-bold">{item.title}</p>
                                            <p className="ms-auto text-gray">{item.hour}</p>
                                        </div>
                                        <div className="d-flex">
                                            <p>{item.description}</p>
                                            <img src={item.icon} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
